.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  overflow: hidden;

  &::after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 50vh;
    background-color: white;
    z-index: 0;
  }

  .lazyImage {
    position: fixed;
    display: block;
    width: 100vw;
    height: 100vh;
    min-width: 1px;
    min-height: 1px;
    top: 0;
    z-index: 0;
    background-position: left center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }

  .outerCircle,
  .innerCircle {
    position: fixed;
    left: 0;
    top: 0;
    border-radius: 50%;
    width: 220vw;
    height: 220vw;
    transform: translateX(-62vw);
  }
  .outerCircle {
    z-index: 1;
    margin-top: 100px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .innerCircle {
    z-index: 2;
    margin-top: 130px;
    background-color: white;
  }

  .info {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 190px);
    margin-top: 190px;
    overflow: auto;

    & > * {
      padding: 0 7.5vw;
    }

    h1 {
      margin: 8px 0;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
    }
    h2 {
      margin: 12px 0 0 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }
    button {
      align-self: center;
      margin: 32px 0;
      padding: 6px 20px;
    }
    .textContainer {
      overflow-y: scroll;
    }
  }

  @media screen and (min-width: 600px) {
    .info {
      height: calc(100% - 230px);
      margin-top: 230px;
    }
  }

  @media screen and (min-width: 1024px) {
    &:after {
      display: none;
    }

    .outerCircle,
    .innerCircle {
      left: 50%;
      top: 50%;
      width: 140vh;
      height: 140vh;
      max-width: 95vw;
      max-height: 95vw;
      transform: translate(-50%, -50%);
    }
    .innerCircle {
      margin-top: 100px;
      transform: translate(-50%, -50%) scale(0.92);
      transform-origin: center;
      background-color: rgba(255, 255, 255, 0.8);
    }

    .info {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 96px); // header height
      margin-top: 96px;
      padding-top: 48px;
      overflow: auto;

      & > * {
        width: 100vh;
        padding: 0 7.5vw;
        align-self: center;
      }

      h1 {
        font-size: 32px;
        line-height: 48px;
      }
      button {
        width: unset;
        margin: 32px 7.5vw;
      }
      .textContainer {
        overflow-y: scroll;
      }
    }
  }
}
