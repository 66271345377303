.modalWrapper, .fullScreenActiveModalWrapper {
  > div > div > div {
    max-width: 100% !important;
    background-color: #e4e4e4 !important;
  }
}

.fullScreenActiveModalWrapper {
  > div > div > div {
    height: 100% !important;
    min-height: 90vh !important;
  }
}

.backDrop {
  width: 100% !important;
  min-height: 90vh;
}

.consentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 95%;
  min-height: 50vh;
}

.fullScreenContentWrapper {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
}
