.inviteBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .docButton {
    padding: 15px 0px;
  }
  .invitedUser {
    cursor: pointer;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.5) 20%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    gap: 8px;
    z-index: 15;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      z-index: 10;
    }

    .usersAvatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      z-index: 5;
    }
  }
}

.inviteListModal {
  max-width: 812px !important;
  width: fit-content !important;
  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .viewAnswersBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: fit-content;
    }
    .usersListBlock {
      display: flex;
      flex-direction: column;
      width: 100%;
      .itemList {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .userDataBlock {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          .itemAvatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          .userinfo {
            display: flex;
            flex-direction: column;
            p:first-child {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
            }

            p:last-child {
              font-size: 14px;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

.modal {
  max-width: 604px !important;
  width: fit-content !important;

  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .radioWrapper {
      max-height: calc(100vh - 560px);
      overflow-y: auto;
      padding-right: 36px;
      margin-right: -36px;
    }

    .buttonWrapper {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      gap: 12px;

      .modal_button {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 48px;
        border: none;
      }
    }
  }

  @media screen and (max-width: 760px) {
    .modalContent {
      .modal_inviteButton > button {
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .modalContent {
      .radioWrapper {
        max-height: 100%;
      }

      .buttonWrapper {
        flex-direction: column;

        .modal_button {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
