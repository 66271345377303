.modal {
  max-width: 608px !important;
  width: 100% !important;

  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .treeWrapper {
      max-height: calc(100vh - 260px);
      overflow-y: auto;
      padding-right: 36px;
      margin-right: -36px;
    }

    .buttonWrapper {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      column-gap: 40px;
      row-gap: 12px;

      .modal_button {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 48px;
        border: none;
      }
    }
  }

  @media screen and (max-width: 760px) {
    .modalContent {
      .modal_inviteButton > button {
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .modalContent {
      .treeWrapper {
        max-height: 100%;
      }

      .buttonWrapper {
        flex-direction: column;

        .modal_button {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.scrollableModal {
  max-width: 100% !important;
  width: fit-content !important;
  min-width: 320px;

  :global .ant-modal-content {
    max-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 16px;
    .modalBody {
      overflow-y: auto;
      flex: 1; /* Занимает все оставшееся пространство */
      max-height: 700px;
    }
    /* Подвал может иметь произвольную высоту */
    .modalFooter {
      /* Стили для подвала */
      padding: 10px 16px;
      background-color: #fff;
      text-align: right;
    }
  }
}

.usersListContainer {
  overflow-x: auto;

  .usersListBlock {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    min-height: 60px;
    align-items: center;
    margin-bottom: 10px;
    > div {
      flex-basis: 100px;
      flex-grow: 0;
      flex-shrink: 0;
      height: 100%;
    }
  }
}
