.signUpWrapper {
  display: flex;
  flex-direction: column;

  .subtitleSignUp {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 20px;
    p {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .signUpForm {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .inputRow {
      display: flex;
      flex-direction: row;
      gap: 12px;
    }
    .checkBoxBlock {
      width: fit-content;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      gap: 12px;

      .checkboxControl {
        padding: 0px 11px 0px 0px !important;
      }

      .label {
        margin: 0 !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .signUpAction {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .formLink {
        font-size: 16px;
        font-weight: 400;
        color: #143471;
        text-decoration: underline;
        cursor: pointer;
        letter-spacing: 0.1px;
        width: fit-content;
      }
    }
  }
}
