.avatarRow {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .fio {
    margin-left: 10px;
    white-space: nowrap;
    max-width: 240px;
    position: relative;
    > p {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > svg {
      position: absolute;
      top: -12px;
      right: -3px;
    }
    @media screen and (max-width: 600px) {
      max-width: 140px;
    }
  }

  > button {
    position: relative;
    top: -16px;
  }

  .role {
    > svg {
      position: absolute;
      bottom: -6px;
      left: 10px;
    }
  }

  .avatar {
    width: 48px;
    height: 48px;

    @media screen and (max-width: 780px) {
      width: 40px;
      height: 40px;
    }
  }
}
