.errorContainer {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #efefef !important;
  .errorImage {
    width: 80%;
    height: 80%;
    margin: auto;
    background-image: url('../../../public/oops.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .errorText {
      font-size: 24px;
      color: #333;
      margin-bottom: 40px;
    }

    #errorButton {
      background-color: #212121 !important;
      border: 1px solid #212121 !important;
      color: #fff !important;
      border: none;
      border-radius: 5px;
      text-decoration: none;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #000 !important;
      }
    }
  }
}
