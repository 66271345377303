$greenColor: #4caf50;
$redColor: #d32f2f;
$blackColor: #212121;

.formContainer {
  form {
    min-height: 150px;
    padding: 20px;

    h1 {
      margin-bottom: 0;
      margin-top: -8px;
      line-height: normal !important;
      font-size: 36px !important;
      margin-bottom: 28px;
    }

    .headerContainer {
      width: fit-content;
      display: flex;
      align-items: center;
      p {
        font-size: 28px;
        font-weight: 600;
      }

      &.companyHeaderContainer {
        white-space: unset;
      }

      span {
        display: inline-block;
      }
      .icons {
        display: inline-block;
      }
    }

    .uploadLink {
      width: 100%;
      display: flex;
      align-items: start;
      padding-left: 20px;
      margin-top: 7px;

      p {
        font-size: 16px;
        font-weight: 600;
        color: #18397a;
        text-align: end;
        text-transform: uppercase;
      }
    }
    .inviteBlock {
      display: flex;
      .helpIcon {
        width: 26px;
        height: 26px;
        margin-left: -12px;
      }
    }
    .infoIcon {
      height: 20px;
      width: 20px;
    }
  }

  .navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 32px 0 0 0;

    .addSourceNavBtn {
      margin: 0 calc(50% - 75px) 20px calc(50% - 75px);
    }
  }

  @media screen and (min-width: 1024px) {
    margin-top: 16px;
    height: calc(55% - 16px);

    form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      max-height: 100%; //calc(100% - 82px);
      padding: 20px 32px 24px 32px;
      overflow-y: auto;
      margin-top: auto;

      h1 {
        margin-top: -16px;

        .tableArrow {
          margin-left: 8px;
        }
      }

      .infoIcon {
        margin: 0 0 0 4px;
      }

      .infoLabel {
        font-size: 14px;
      }

      .curSourceName {
        margin: -45px 0 0 260px;
        width: unset;
      }

      .sourceName {
        width: 59%;
        max-width: 740px;
        margin-top: 12px;
      }

      .componentLabel {
        line-height: 32px;
      }

      .componentInputs {
        display: grid;
        grid-gap: 12px 16px;
        grid-template-rows: auto auto auto;
        grid-template-columns: 0.85fr 1fr 1fr 1fr 1fr 0.85fr;
        grid-template-areas:
          'header header header header header header'
          'input input input input input input'
          'input input input input input input';
        margin-top: 24px;

        .componentLabel {
          grid-area: header;
        }

        .componentInput {
          margin-bottom: 0;

          &.input1 {
            grid-column: 1 / 3;
          }
          &.input2 {
            grid-column: 3 / 5;
          }
          &.input3 {
            grid-column: 5 / 7;
          }
          &.input4 {
            grid-column: 1 / 4;
          }
          &.input5 {
            grid-column: 4 / 7;
          }
        }
        &.col1 {
          grid-template-rows: auto;
          grid-template-columns: 1fr;
          grid-template-areas:
            'header'
            'input';
        }
        &.col2,
        &.col4 {
          grid-template-rows: auto auto;
          grid-template-columns: 1fr 1fr;
          grid-template-areas:
            'header header'
            'input input';
        }

        &.col3 {
          grid-template-rows: auto auto auto;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas:
            'header header header'
            'input input input'
            'input input input';
        }
      }

      .isNotAvailable {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .info {
          width: calc(100% - 250px);
        }
        .checkbox {
          line-height: 32px;
        }
        button {
          margin: 0;
          margin-left: auto;
        }

        &.fullWidth {
          width: 100%;

          & + button {
            margin-top: 10px;
          }
        }

        &.partWidth {
          width: 100%;
          margin-bottom: 0;
        }
      }

      .docButtonCont {
        width: 100%;
        text-align: end;
      }

      .addButton {
        margin: 0 8px 0 auto;
      }
    }

    .navigation {
      width: 72vw;
      max-width: 1328px;
      padding-left: 36vw;

      .backBtn {
        order: -1;
      }

      .addSourceNavBtn {
        margin: 0;
      }
    }
  }
  .itemsTitle {
    width: 100%;
    height: fit-content;
    margin-bottom: 24px;
    p {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .inputsBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .inputRow {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-bottom: 14px;
    }

    .checkboxContainer {
      width: 420px;
      display: flex;
      align-items: center;
      gap: 8px;
      input[type='checkbox'] {
        width: 20px;
        height: 20px;
      }

      label {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .costInput {
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      appearance: textfield;
    }
    .input {
      width: 100%;
    }
  }

  .addBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 62px;
    .conformBlock {
      display: flex;
      align-items: center;
      gap: 12px;

      svg > path {
        fill: #18397a !important;
      }

      p {
        color: #18397a;
        font-size: 16px;
        font-weight: 600;
        line-height: 48px;
        text-transform: uppercase;
      }
    }
    .addButton {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      font-size: 16px;
      font-weight: 600;
      .addIcon {
        width: 25px;
        display: flex;
        svg > path {
          fill: #18397a !important;
        }
      }
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .lastupdateInfo {
      display: flex;
      flex-direction: row;

      .checkIcon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        svg > path {
          fill: $greenColor !important;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #757575;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    form {
      .infoLabel {
        font-size: 16px;
        line-height: 24px;
      }

      .componentInputs {
        grid-template-rows: auto auto;
        grid-template-columns: 0.85fr 1fr 1fr 1fr 0.85fr;
        grid-template-areas:
          'header header header header header'
          'input input input input input';

        .componentInput {
          &.input1,
          &.input2,
          &.input3,
          &.input4,
          &.input5 {
            grid-column: unset;
          }
        }
      }

      .isNotAvailable {
        justify-content: space-between;

        &.partWidth {
          width: calc(100% - 250px);
          margin-bottom: 0;
          margin-right: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 1920px) {
    form {
      .curSourceName {
        margin-top: -50px;
        width: unset;
      }

      .componentInputs {
        &.col4 {
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-areas:
            'header header header header'
            'input input input input';
        }
      }
    }
  }
}
