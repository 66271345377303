$greenColor: #4caf50;
$greyColor: #757575;

.QuestionButton {
  background-color: white;
  border-radius: 8px;
  margin-bottom: -25px;
  padding: 0px 0.8rem 25px;
  height: 66px;
  margin-right: 6px;
  border: 0;
  box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
  width: auto;

  overflow: hidden;
  text-align: left;

  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: left;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-size: 12px;
  line-height: 40px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 4px;
  color: rgb(24, 57, 122);
  white-space: nowrap;

  .transform {
    max-width: 2.9rem;
    min-width: 2.9rem;
    transition: max-width 0.8s ease-in-out;
    transition-delay: 0;

    &:hover {
      border: 0;
      max-width: 20rem;
      transition: max-width 1s ease-in-out;
      transition-delay: 0.05s;
    }
  }

  .qbuttonlabel {
    margin-left: 1rem;
  }

  .qbuttonsvg {
    overflow: visible;
  }
  .answered {
    fill: $greenColor;
    color: $greenColor;
  }
  .required {
    fill: $greyColor;
    color: $greyColor;
  }
}
