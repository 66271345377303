.__modalRoot {
  &.modalWide {
    max-width: 100%;
    > div > div {
      max-width: 100% !important;
    }
  }
}
.modal {
  max-width: 100% !important;
  width: fit-content !important;

  .columnWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .rowWrapper {
    display: flex;
    max-width: fit-content;
    flex-wrap: wrap;
  }

  .wide {
    width: 100%;
    max-width: 100%;
  }

  .linkWrapper {
    display: flex;
    margin-left: 25px;
    margin-bottom: 25px;
    flex-direction: column;
    align-items: flex-start;
  }

  .noFiles {
    margin-bottom: 20px;
  }

  .errorText {
    .tooltip {
      display: flex;
      gap: 8px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;

    li {
      list-style-type: none;

      .fileBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 12px;

        .fileInfoBlock {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 12px;

          .file {
            svg {
              margin-right: 16px;
            }

            .accepted {
              color: green;
            }

            .rejected {
              color: red;
            }

            .loading {
              color: gray;
            }

            .fileName {
              text-wrap: nowrap;
              overflow: ellipsis;
            }
          }
          svg {
            vertical-align: middle;
          }

          path:hover {
            color: gray;
          }
        }
      }
    }
  }

  .disabled {
    color: gray;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.docButton {
  svg {
    fill: #1f4a9d;
  }
}
.progressBarContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 100%;

  .progressBar {
    height: 100%;
    width: 0;
    background-color: #efefef;
    transition: width 0.6s ease;

    &.saved {
      width: 100%;
      background-color: transparent;
    }
    &.loading {
      width: 33%;
    }

    &.uploadLink {
      width: 66%;
    }

    &.uploaded {
      width: 100%;
    }
  }
}
