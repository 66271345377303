.modal {
  max-width: 604px !important;
  width: fit-content !important;
  .modalForm {
    margin-top: 10px;
    padding: 0;
    .modalContent {
      display: flex;
      flex-direction: column;
      .modalAbout {
        margin-bottom: 12px;
      }
      .buttonWrapper {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 12px;

        .modal_button {
          display: flex;
          align-items: center;
          gap: 10px;
          height: 48px;
          border: none;
        }
      }

      @media screen and (max-width: 480px) {
        .buttonWrapper {
          flex-direction: column;

          .modal_button {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

:global .ant-form-item {
  margin-bottom: 12px;
}

.modal_input {
  width: 215px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 815px) {
    width: 100% !important;
  }
}

.select_container {
  @media screen and (max-width: 815px) {
    width: 100% !important;
  }
}

.sendIcon {
  width: 24px;
  height: 24px;
  line-height: normal !important;

  svg > path {
    fill: #ffffff !important;
  }
}

.errorMessage {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: red;
  line-height: 17px;
}

.modal_email {
  height: 48px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  text-align: start;
  padding-left: 10px;
  padding-top: 7px;
}

.inputLabel {
  text-decoration: none;
  /* padding: 25px; */
  /* padding-left: 30px; */
  margin-left: -5px !important;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px !important;
  letter-spacing: 0;
  text-align: left;
}

.modal_content_first {
  padding-top: 12px;
}

.modal_select {
  width: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: start;
  background: rgba(255, 255, 255, 1);

  fieldset {
    border-color: #e0e3e7;
  }
}

.modal_selectInput {
  border-radius: 12px !important;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 815px) {
    flex-direction: column !important;
  }
}

.modal_textArea {
  width: 100%;
  // height: 120px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  border-radius: 12px;
  margin-bottom: 12px;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}

.phone {
  display: flex;
  align-items: center;
  height: 48px;
  background: transparent !important;
  border: none !important;
  border-radius: 12px !important;

  & > div {
    padding: 0 0 0 16px !important;

    &:focus,
    &:hover {
      background-color: transparent !important;
    }

    &[aria-expanded='true'] {
      background: transparent !important;
    }
  }
}

.phoneInput {
  font-size: 16px;
  color: inherit;
  font-family: Montserrat, sans-serif;
  height: 48px !important;
  width: 100% !important;
  background: #ffffff !important;
  border: 1px solid #efefef !important;
  border-radius: 12px !important;
  &:focus,
  &:focus-within,
  &:hover {
    outline: none !important;
    border-color: #e0e3e7;
    box-shadow: 0 4px 14px #0000000a;
  }
}
