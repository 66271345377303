.formWrapper {
  padding: 10px 0;
}

.form {
  margin-top: 2px;
  width: 100%;

  > div {
    width: 100%;
  }
}

.inputWrapper {
  position: relative;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 16px;
}
.formInput {
  position: relative;
  width: 100%;
  font-size: 16px;
  font-weight: 400;

  input,
  label {
    font-size: 16px;
  }

  @media screen and (max-width: 772px) {
    input,
    label {
      font-size: 14px;
    }
  }

  & > label {
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-style: normal;
      font-weight: 400;
    }
  }
}

.formError {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: unset;
  color: #d32f2f;
}

.formLink {
  font-size: 16px;
  font-weight: 400;
  color: #143471;
  text-decoration: underline;
  cursor: pointer;
  letter-spacing: 0.1px;
  width: fit-content;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    margin-right: 5px;
  }

  @media screen and (max-width: 460px) {
    margin-top: 8px;
  }
}

.formBottom {
  margin-top: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: 460px) {
    flex-direction: column-reverse;
    align-items: inherit;
    margin-top: 12px;
  }

  .rightButtons {
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 460px) {
      flex-direction: column-reverse;
      align-items: inherit;
      margin: 12px 0;
      gap: 12px;
    }

    .googleBtn {
      cursor: pointer;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.formSubmitButton {
  margin: 0 0 0 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  flex-shrink: 0;
  max-width: unset;
  border-radius: 4px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }

  @media screen and (max-width: 370px) {
    line-height: 18px;
    padding: 5px 6px;
  }
}
