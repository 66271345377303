.container {
  flex-direction: column;
  height: 100%;
  padding: 0 24px;

  .chartArea {
    display: block;
    height: 50vh;
    width: calc(100% + 140px); // magic number from marginSize in Chart component
    margin: 0 -24px;
    padding: 10px;
  }

  .textArea {
    max-width: 600px;

    h1 {
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 32px;
      font-weight: 600;
    }

    button {
      width: fit-content;
      margin: 32px auto;
    }
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 0 5vw;

    .chartArea {
      height: unset;
      width: 50%;
      margin: unset;
      padding: 10px;
    }

    .textArea {
      width: 50%;
      padding-right: 3vw;
      margin: auto 0 auto auto;

      h1 {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 36px;
      }

      h4 {
        margin-bottom: 0;
      }

      .text {
        height: fit-content;
        max-height: 60vh;
        padding-right: 20px;
        overflow: auto;
        p {
          margin-block: 0;
          margin-bottom: 8px;
        }
      }

      button {
        margin: 0;
        margin-top: 28px;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    padding: 0 12vw;
  }

  .noChart {
    margin: auto auto auto auto !important;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
